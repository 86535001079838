import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

import { hasWindow } from "../util/dom"

import StyleSelector from "./MapStyleSelect"
import styled from "styled-components"

// This wrapper must be positioned relative for the map to be able to lay itself out properly
const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: relative;
  flex: 1 0 auto;
`

const Map = ({
  width,
  height,
  zoom,
  center,
  bounds,
  padding,
  styles,
  sources,
  layers,
  minZoom,
  maxZoom,
  onMapClick,
}) => {
  const mapboxToken =
    "pk.eyJ1IjoiZW50c29lIiwiYSI6ImNrY2FudThzdTBvYWEycmxrc2tmYTJjcnAifQ.r8ZSix-KZOi5Trq_z5L1Nw"

  // this ref holds the map DOM node so that we can pass it into Mapbox GL
  const mapNode = useRef(null)

  // this ref holds the map object once we have instantiated it, so that we
  // can use it in other hooks
  const mapRef = useRef(null)
  // construct the map within an effect that has no dependencies
  // this allows us to construct it only once at the time the
  // component is constructed.
  useEffect(() => {
    if (!mapboxToken) {
      console.error(
        "ERROR: Mapbox token is required in gatsby-config.js siteMetadata"
      )
    }

    // if there is no window, we cannot render this component
    if (!hasWindow) {
      return null
    }

    let mapCenter = center
    let mapZoom = zoom

    // If bounds are available, use these to establish center and zoom when map first loads
    // if (bounds && bounds.length === 4) {
    //   const { center: boundsCenter, zoom: boundsZoom } = getCenterAndZoom(
    //     mapNode.current,
    //     bounds,
    //     padding
    //   )
    //   mapCenter = boundsCenter
    //   mapZoom = boundsZoom
    // }

    // Token must be set before constructing map
    mapboxgl.accessToken = mapboxToken

    const map = new mapboxgl.Map({
      container: mapNode.current,
      style: `mapbox://styles/mapbox/${styles[0]}`,
      center: mapCenter,
      zoom: mapZoom,
      minZoom,
      maxZoom,
    })
    mapRef.current = map
    window.map = map // for easier debugging and querying via console

    map.addControl(new mapboxgl.NavigationControl(), "top-right")

    if (styles.length > 1) {
      map.addControl(
        new StyleSelector({
          styles,
          token: mapboxToken,
        }),
        "bottom-left"
      )
    }

    map.on("load", () => {
      console.log("map onload")
      // add sources
      Object.entries(sources).forEach(([id, source]) => {
        map.addSource(id, source)
      })

      // add layers
      layers.forEach(layer => {
        map.addLayer(layer)
      })
    })

    let clickedStateId = null;
    // hook up map events here, such as click, mouseenter, mouseleave
    // e.g., map.on('click', (e) => {})
    map.on("click", "1", e => {
      // Change the cursor style as a UI indicator.
      // map.getCanvas().style.cursor = 'pointer';
      let features = map.queryRenderedFeatures(e.point, {
        layers: ["1"],
      })
      console.log(features)
      if (e.features.length > 0) {
        if (clickedStateId) {
            map.setFeatureState(
                { source: 'oregon', id: clickedStateId },
                { click: false }
            );
        }
        clickedStateId = e.features[0].id;
        map.setFeatureState(
            { source: 'oregon', id: clickedStateId },
            { click: true }
        );
    }
      onMapClick(features[0].properties)
    })

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on("mouseenter", "1", function (e) {
      map.getCanvas().style.cursor = "pointer"
    })

    // Change it back to a pointer when it leaves.
    map.on("mouseleave", "1", function () {
      map.getCanvas().style.cursor = ""
    })

    // when this component is destroyed, remove the map
    return () => {
      map.remove()
    }
  }, [])

  // You can use other `useEffect` hooks to update the state of the map
  // based on incoming props.  Just beware that you might need to add additional
  // refs to share objects or state between hooks.

  // Update clusters when the filtered data change
  useEffect(() => {
    const { current: map } = mapRef
    if (!(map && map.isStyleLoaded())) return

    const geoJSON = sources.oregon.data ? sources.oregon.data : []

    map.getSource('oregon').setData(geoJSON)
  }, [sources])

  return (
    <Wrapper width={width} height={height}>
      <div ref={mapNode} style={{ width: "100%", height: "100%" }} />
      {/* <StyleSelector map={mapRef.current} styles={styles} token={mapboxToken} /> */}
    </Wrapper>
  )
}

Map.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  bounds: PropTypes.arrayOf(PropTypes.number),
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  styles: PropTypes.arrayOf(PropTypes.string),
  padding: PropTypes.number,
  sources: PropTypes.object,
  layers: PropTypes.arrayOf(PropTypes.object),
  onMapClick: PropTypes.func,
}

Map.defaultProps = {
  width: "auto",
  height: "100%",
  center: [0, 0],
  zoom: 0,
  bounds: null,
  minZoom: 0,
  maxZoom: 24,
  styles: ["light-v10", "streets-v11", "satellite-streets-v11"],
  padding: 0.1, // padding around bounds as a proportion
  sources: {},
  layers: [],
}

export default Map
