/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Map from "./Map"
import { Layout as AntLayout } from "antd"
import styled from "styled-components"
import pointsData from "../util/applicationPoints"
import { Statistic, Row, Col, Table } from "antd"
import ApplicationDetails from "./ApplicationDetails"
import { scaleSqrt, scaleLinear } from "d3-scale"

import Select from 'react-select';

const ownerFilterValues = [
  "AG 6HQ Hanover Real Estate Limited",
  "Aevel Unlimited Company",
  "Aledo Donabate Limited",
  "Ardstone Homes Limited",
  "Ardstone Homes Ltd.",
  "Atlas GP Limited",
  "Atlas GP Ltd",
  "Atlas GP Ltd.",
  "Balark Investments Limited",
  "Balark Trading GP Limited",
  "Ballymore Property Developments Limited",
  "Belwall Limited",
  "Brian Kennedy",
  "Broadcrest Ltd",
  "Cairn Homes Properties Limited",
  "Canmar Properties Limited",
  "Carnamadra Ltd",
  "Carrey Issuer DAC",
  "Carrs Lane Ltd",
  "Castleshore Investments Limited",
  "Charlemont Regeneration",
  "Chillidale Limited",
  "Cinamol Ltd",
  "Circle Voluntary Housing Association",
  "Citywest Homes Developments Ltd",
  "Claregrove Developments Limited",
  "Clarke Family Partnership",
  "Clondev Properties Ltd",
  "Coastdawn Limited",
  "Conskig Ltd",
  "Copper Bridge C 2015 ICAV",
  "Copper Bridge C2015 ICAV",
  "Cornel Living Limited",
  "Cosgrave Developments",
  "Crekav Trading GP Limited",
  "Crekav Trading GP Ltd",
  "Crekav Trading GP Ltd.",
  "Crosswaithe Developments",
  "Curve Devco\r\nLimited",
  "DBTR-SCR1 Fund, a Sub-Fund of the CWTC\r\nMulti Family ICAV",
  "Davy Platform ICAV acting on behalf of its sub fund Elm Real Estate Investments",
  "De La Salle Brothers",
  "Declan Taite and Anne O\u2019Dwyer, Joint Statutory\r\nReceivers to Certain Assets of Michael Doran and\r\nMartin Doran",
  "Derryroe Limited",
  "Dwyer Nolan Developments Limited",
  "Dwyer Nolan Developments Ltd",
  "EWR Innovation Park Limited",
  "Eastwise Property Ltd",
  "Elchior Construction Ltd.",
  "Garlandbrook Ltd.",
  "Gerard Gannon Properties",
  "Glenveagh Homes Limited",
  "Greenacre Residential DAC",
  "Greenleaf Homes Ltd",
  "HLK Development Ltd",
  "Horoprops Limited",
  "Hughes Planning & Development",
  "IDV Boyne Future Ltd",
  "IPUT PLC",
  "Jacko Investments Limited",
  "KW PRS ICAV",
  "KW PRS ICAV acting for and on behalf of its sub-fund KW PRS Fund 2",
  "KW PRS ICAV acting for and on behlf of sub-fund KW PRS Fund 11",
  "Kategale Limited",
  "Kimpton Vale Limited",
  "Lulani Dalguise Ltd",
  "MKN Investments Limited & Caltrack Limited\r\n(trading as Omni Shopping centre Consortium)",
  "MKN Property Group",
  "Majick Hour Limited",
  "Marblegate Limited",
  "Midgard Construction Limited",
  "O'Flynn Construction (Kinseally)",
  "OBSF(I) Limite",
  "PWCPS",
  "Parsis Ltd",
  "Paul and David Butler",
  "Pembroke Partnership Limited",
  "Power Scaffolding Supplies Limited",
  "Prussia Properties Limited",
  "Randelswood Holdings Ltd.",
  "Romeville Development Ltd.",
  "Romeville Developments Ltd.",
  "Roseberry Investments Limited",
  "Ruirside Developments Ltd",
  "SDCC/Cluid Housing",
  "Sandyford GP Limited",
  "Seabren Developments",
  "Search 4 Alpha CVBA",
  "Seven Cabra Real Estate Limited",
  "Shannon Homes Construction ULC",
  "South Dublin County Council (Housing)",
  "Southern Oak",
  "Spencer Place Development",
  "Spencer Place Development Company Limited",
  "Spencer Place Development Company Ltd",
  "Spencer Place Devlopment Company",
  "St. Clare\u2019s GB3 Limited",
  "St. Edmunds Phase 3 Limited",
  "St. Marnock\u2019s II Designated Activity\r\nCompany",
  "Steelworks Property Developments Limited",
  "TC Fumbally Properties Ltd.",
  "The Pembroke Road Partnership",
  "The Skerries Road Partnership",
  "Thomas McMullan",
  "Tony Gaule",
  "Trinity College Dublin",
  "Winsac Limited",
  "Winterbrook Homes (Dalkey) Ltd",
]

const max = (values, valueof) => {
  var n = values.length,
    i = -1,
    value,
    max

  if (valueof == null) {
    while (++i < n) {
      // Find the first comparable value.
      if ((value = values[i]) != null && value >= value) {
        max = value
        while (++i < n) {
          // Compare the remaining values.
          if ((value = values[i]) != null && value > max) {
            max = value
          }
        }
      }
    }
  } else {
    while (++i < n) {
      // Find the first comparable value.
      if ((value = valueof(values[i], i, values)) != null && value >= value) {
        max = value
        while (++i < n) {
          // Compare the remaining values.
          if ((value = valueof(values[i], i, values)) != null && value > max) {
            max = value
          }
        }
      }
    }
  }

  return max
}

const getCircleRadius = data => {
  let maxCases = max(
    data.features.map(e => {
      return e.properties["no of units"] === undefined
        ? 0
        : +e.properties["no of units"]
    })
  )

  console.log("The max number of units is ", maxCases)

  const radiusScale = scaleSqrt() // instead of scaleLinear()
    .domain([0, maxCases])
    .range([3, 30])

  const features = data.features.map(d => {
    d.properties.circleRadius = radiusScale(+d.properties["no of units"])
    return d
  })

  return {
    type: "FeatureCollection",
    features: features,
  }
}

const creatBubbleLegend = () => {
  let maxCases = 685
  const radiusScale = scaleSqrt() // instead of scaleLinear()
    .domain([0, maxCases])
    .range([3, 30])
  let currentScale = radiusScale
  let maxRadius = 35
  let maxRadiusValue = currentScale.invert(maxRadius)
  let maxDataValue = maxCases

  console.log("maxRadiusValue > maxDataValue", maxRadiusValue, maxDataValue)
  if (maxRadiusValue > maxDataValue * 1.5) {
    maxRadiusValue /= 10
  }

  let num = 10
  let exp = 1

  while (num < maxRadiusValue) {
    exp += 1
    num = Math.pow(5, exp)
    console.log("EXP num", exp, num)
  }

  let minExp = Math.max(0, exp - 3)
  console.log("Min EXP", minExp, exp)

  let values = []
  for (let i = minExp; i < exp; i++) {
    values.push(Math.pow(5, i))
  }

  console.log("Bubble values", values)

  return (
    <>
      <p style={{ textAlign: "center", marginBottom: "40px" }}>
        <strong>No. of Units</strong>
      </p>
      <KeyBubbles className="key-bubbles">
        <KeyBubbleLabel
          className="key-bubble-label"
          style={{ display: "inline" }}
        >
          <span className="min-key-value">{values[0]}</span>
        </KeyBubbleLabel>
        <KeyBubbleWrapper>
          {values.map(v => (
            <KeyBubble
              key={v}
              style={{
                width: Math.round(2 * currentScale(v)) + "px",
                height: Math.round(2 * currentScale(v)) + "px",
              }}
            ></KeyBubble>
          ))}
        </KeyBubbleWrapper>
        <KeyBubbleLabel
          className="key-bubble-label"
          style={{ display: "inline" }}
        >
          <span className="min-key-value">{values[values.length - 1]}</span>
        </KeyBubbleLabel>
      </KeyBubbles>
    </>
  )
}



const layers = [
  {
    id: "1",
    source: "oregon",
    type: "circle",
    paint: {
      // 'circle-radius': [
      //   '/',
      //   ['number', ['to-number', ['get', 'no of units']], 120],
      //   40
      // ],
      "circle-radius": ["get", "circleRadius"],
      "circle-color": "#ff0000",
      "circle-opacity": 0.2,
      "circle-stroke-color": [
        "case",
        ["boolean", ["feature-state", "click"], false],
        "#000",
        "#ff0000",
      ],
      "circle-stroke-width": [
        "case",
        ["boolean", ["feature-state", "click"], false],
        1,
        0.5,
      ],
    },
  },
]


const KeyBubbles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`

const KeyBubbleLabel = styled.div`
  margin: 0 5px;
  font-size: 14px;
  font-weight: 300;
`

const KeyBubbleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const KeyBubble = styled.div`
  border: 1px solid #cf1111;
  background-color: rgba(207, 17, 17, 0.2);
  border-radius: 500px;
  margin: 0 3px;
`

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 350px auto;
  grid-gap: 1rem;
  min-height: 100vh;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const StyledHeading = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 20px;
`

const LogoImage = styled.img`
  max-width: 250px;
`

const Wrapper = styled.div`
  
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: #fff;
  width: 300px;
  border-radius: 4px;
  padding: 1rem 0.5rem;

`


const dataSource = [
  {
    key: "1",
    name: "Apartments",
    count: 37881,
  },
  {
    key: "2",
    name: "Houses",
    count: 6373,
  },
  {
    key: "3",
    name: "Student Acc.",
    count: 6068,
  },
]

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Count",
    dataIndex: "count",
    key: "count",
  },
]



class Dashboard extends React.Component {
  state = {
    selectedApp: null,
    ownerFilter: null
  }

  handleOwnerChange = (selectedOption) => {
    if (!selectedOption) this.setState({ownerFilter: ''})
    if (selectedOption) {
      this.setState({
        ownerFilter: selectedOption.value,
      })

    }
  }

  handleMapClick = application => {
    this.setState({
      selectedApp: application,
    })
  }

  filterSources = () => {
    let data = pointsData

    if (this.state.ownerFilter) {
      data = {
        type: "FeatureCollection",
        features: [...data.features.filter(d => d.properties.owner.indexOf(this.state.ownerFilter) > -1)]
      }
    }

    return {
      oregon: {
        type: "geojson",
        data: getCircleRadius(data),
      },
    }
  }
 
  render() {
    return (
      <>
        <GridLayout>
          <aside style={{ height: "100vh" }}>
            <a href="https://www.phr.ie" target="_blank">
              <LogoImage
                src={`https://d33wubrfki0l68.cloudfront.net/65daf0c86e4e1f2f84c9444bfd1892dda7e57260/425bb/assets/graphics/layout/phr-logo-h-pos.svg`}
              />
            </a>
            <StyledHeading>25+ Unit Residential Sites in Dublin</StyledHeading>
            <p>
              This research project comprises applications from 2018 to the
              present day. Both strategic housing projects and regular planning
              applications are captured.
            </p>
            
            {!this.state.selectedApp && (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <Statistic title="Applications" value={104} />
                  </Col>
                  <Col span={12}>
                    <Statistic title="Total Units" value={51696} />
                  </Col>
                </Row>
                <StyledHeading>Summary</StyledHeading>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                  size={"middle"}
                />
                <StyledHeading>
                  Click an application for more info
                </StyledHeading>
              </>
            )}
            {this.state.selectedApp && (
              <ApplicationDetails data={this.state.selectedApp} />
            )}
          </aside>

          <AntLayout>
            <div style={{ height: "100vh", position: 'relative' }}>
            <Wrapper>
            <Select 
              options={
                ownerFilterValues.map(v => {
                  return {value: v, label: v}
                })
              }
              placeholder="Filter map by owner"
              onChange={this.handleOwnerChange}
              isClearable={true}
            />
            <div style={{ marginTop: '1rem' }}>{creatBubbleLegend()}</div>
            </Wrapper>
              <Map
                sources={this.filterSources()}
                layers={layers}
                center={[-6.2994, 53.3341]}
                zoom={10}
                onMapClick={this.handleMapClick}
              />
            </div>
          </AntLayout>
        </GridLayout>
      </>
    )
  }
}

Dashboard.propTypes = {}

export default Dashboard
