import React from "react"
import styled from "styled-components"
import { Statistic, Row, Col, Table, Card, Typography } from "antd"

const { Paragraph } = Typography;

const getKeys = [
  "type",
  "1_bed",
  "2_bed",
  "3_bed",
  "4_bed",
  "4+_bed",
]

const columns = getKeys.map((c,i) => {
  return {
    title: c.replace("_", " "),
    dataIndex: c,
    // key: c,
    render: (value, row, index) => {
      // do something like adding commas to the value or prefix
      return (!value || value === "null") ? "-" : value
    },
  }
})

const formatData = data => {
  return [
    {
      type: "apartments",
      "1_bed": data["apt.1_bed"],
      "2_bed": data["apt.2_bed"],
      "3_bed": data["apt.3_bed"],
      "4_bed": data["apt.4_bed"],
      "4+_bed": data["apt.4+_bed"],
    },
    {
      type: "houses",
      "1_bed": data["house.1_bed"],
      "2_bed": data["house.2_bed"],
      "3_bed": data["house.3_bed"],
      "4_bed": data["house.4_bed"],
      "4+_bed": data["house.4+_bed"],
    },
  ]
}

const StyledHeading = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 10px;
`

const DescOverflowWrapper = styled.div`
  overflow-y: auto;
  max-height: 300px;
`

const ApplicationDetails = ({ data }) => (
  <>
    <Card title={data.ref} extra={<a href={data.url}>Website</a>}>
      <StyledHeading> {data.owner === "null" ?  data.Applicant : data.owner}</StyledHeading>
      <p>Agent {data.agent === "null" ? 'Could not find agent' : data.agent}</p>
      <Row gutter={16}>
        <Col span={12}>
          <Statistic title="Area" value={data["area ha"]} suffix=" ha" />
        </Col>
        <Col span={12}>
          <Statistic title="Total Units" value={+data["no of units"]} />
        </Col>
      </Row>
      <DescOverflowWrapper>
      <Paragraph
          ellipsis={{
            rows: 5,
            expandable: true,
            onEllipsis: ellipsis => {
              console.log('Ellipsis changed:', ellipsis);
            },
            symbol: 'more' 
          }}
        >{data.dev_desc}</Paragraph>
      </DescOverflowWrapper>
      <Row gutter={12}>
        <Col span={7}>
          <Statistic title="Apartments" value={data["apartments"]} />
        </Col>
        <Col span={7}>
          <Statistic title="Houses" value={data["houses"] === "null" ? '-' : data["houses"]} />
        </Col>
        <Col span={7}>
          <Statistic title="Student" value={data["student"] === "null" ? '-' : data["student"]} />
        </Col>
      </Row>
      <Table
        dataSource={formatData(data)}
        columns={columns}
        pagination={false}
        size={"small"}
        rowKey="type"
      />
      <p>
        <strong>Notes</strong>
        <br />
        {data["other notes"]}
      </p>
    </Card>
  </>
)

ApplicationDetails.propTypes = {}

ApplicationDetails.defaultProps = {}

export default ApplicationDetails
